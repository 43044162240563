@import "assets/styles/bootstrap-override.scss";
@import "~vgg-ui-react/dist/styles/index.scss";
@import "remixicon/fonts/remixicon.css";

* {
  scrollbar-width: thin;
  scrollbar-color: $spb-600-color $spn-200-color;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* width */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $spn-200-color;
  border-radius: 5px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: $spb-600-color;
  border-radius: 5px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: $spb-800-color;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Inter", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-gray {
  background-color: $gray-060;
}

.pagination-container {
  padding: 32px 0 !important;

  .pagination-item {
    font-size: 14px !important;

    &.selected {
      color: $primary-color;
      background-color: rgba($primary-color, 0.2);
    }
  }
}

.pagination-nav {
  font-size: 14px !important;
}

.shimmer-line {
  width: 100%;
  height: 8px;
  align-self: center;
  border-radius: 8px;
}

@mixin fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.2s;
}

@mixin fade-out {
  opacity: 0;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.2s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade_in {
  @include fade-in;
}

.fade_out {
  @include fade-out;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: $spn-050-color !important;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: $spn-050-color !important;
}

.table td {
  border-top: none;
}

.table> :not(caption)>*>* {
  border-bottom-width: 0;
}

.table thead {
  background-color: rgba($primary-color, 0.1);
}

.tab-content {
  padding: 0px;
}

.bb {
  &-100 {
    border-bottom: 1px solid rgba($pure-black, 0.1);
  }

  &-200 {
    border-bottom: 1px solid $gray-150;
  }
}

/* Datepicker Styles override */

.react-datepicker {
  border: 1px solid $spn-050-color;

  .react-datepicker__header {
    background-color: $pure-white;
    border-bottom: none;
  }

  .react-datepicker-popper[data-placement^="bottom"] {
    .react-datepicker__triangle::before {
      border-bottom-color: inherit !important;
      display: none;
    }
  }

  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: transparent;
  }

  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after,
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
    border: unset !important;
    border-width: 0px !important;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: $spb-900-color;
    line-height: 2.2rem;
    margin: 0.166rem;
    height: 35px;
    width: 35px;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: $primary-color;
    color: $pure-white;
    border-radius: 50%;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 50%;
    background-color: $primary-color;
    color: $pure-white !important;
  }
}

/* End Datepicker styles override */

@import "react-datepicker/dist/react-datepicker.css";