@import "~vgg-ui-react/dist/styles/partials";
@import "../../assets/styles/common/colors.scss";

.onboard-wrapper {
  .f-11 {
    flex: 11 !important;
  }
  .msg-wrapper {
    flex: 1;
    font-size: 1.5rem;
  }

  .learn-more-btn {
    background: $pure-white;
    border: 1px solid $gray-100;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $btn-color-gray;
  }

  .onboard-btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
  .head-section {
    &__icon {
      background: $bg-head;
      border-radius: 6px;
      color: $primary-color;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      padding: 10px;
      cursor: pointer;
      transition: opacity 0.3s;

      i {
        font-size: 25px;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    &__title {
      .pg-title {
        font-weight: $sp-fontweight--600;
      }
      .pg-subtitle {
        font-weight: $sp-fontweight--600;
      }
    }
  }

  .card-section {
    background: $bg-card;
    border: 1px solid $gray-100;
    border-radius: 4px;

    .content-paragraph {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.048px;
      color: $dark-100;
    }

    .footnote {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      /* or 114% */

      letter-spacing: 0.4px;

      /* SPN/SPN 900 */

      color: $spb-900-color;
    }
  }
}
