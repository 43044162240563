@import "../../assets/styles/common/colors.scss";

.modal__body {
  padding: 20px 16px 60px 16px !important;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 70vh;

  .alert__icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: $spr-100-color;
    color: $spr-500-color;
  }

  .notice {
    color: $spn-500-color;
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.4px;
    max-width: 90%;
  }
}

.main__modal {
  max-width: 680px;
}
.gap-2 {
  gap: 1rem;
}
