.configure-container {
  .bg-gray {
    background-color: #e1e1e1;
  }

  .back-btn {
    cursor: pointer;
  }

  .actionContainer {
    width: 150px;
  }

  .show-kyc {
    font-size: 12px;
    color: #175fff;
    cursor: pointer;
    font-weight: 500;
    letter-spacing: -0.05px;
  }

  .kyc-list {
    background: #f4f5f6;
    border-radius: 5px;
    padding: 24px;
    max-height: 50vh;
    overflow-y: auto;

    .left {
      border-right: 1px solid #d7dce0;
      display: inline-block;
    }
  }
}
