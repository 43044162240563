@import "../../../assets/styles/common/colors.scss";
@import "../../../assets/styles/common/mixins.scss";

.export-modal-container {
  label {
    @include text(400, 14px, 16px, $dark-100);
    margin-bottom: 16px;
  }

  .submit-btn {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    margin-top: 24px;
  }
  .modal-header {
    padding: 28px 24px 4px 14px !important;
  }
  .modal__body {
    padding-bottom: 30px !important;
  }
  .error-desc {
    @include text(400, 12px, 14px, $spr-500-color);
  }

  .text-small {
    font-size: 0.75rem;
  }
}
