.switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: rgba(212, 217, 221, 0.8);
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  overflow: hidden;

  &.checked {
    background: rgba(23, 95, 255, 0.8);
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.lg {
    width: 100px;
    height: 50px;
  }

  &.md {
    width: 64px;
    height: 36px;
  }

  &.sm {
    width: 32px;
    height: 18px;
  }
}

.switch-label .switch-button {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;

  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);

  &.lg {
    width: 45px;
    height: 45px;
  }

  &.md {
    width: 32px;
    height: 32px;
  }

  &.sm {
    top: 1px;
    width: 16px;
    height: 16px;
  }
}

.switch-checkbox:checked + .switch-label .switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switch-label:active .switch-button {
  width: 60px;
}
