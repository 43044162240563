@import "../../assets/styles/common/colors.scss";

.kyc-toggle-btn {
  background: $spn-050-color !important;
  border: 1px solid $gray-100 !important;
  border-radius: 4px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  display: flex !important;
  align-items: center !important;
  letter-spacing: -0.05px !important;
  color: $spn-500-color !important;
  font-size: 16px !important;
  line-height: 16px !important;
  display: flex !important;
  align-items: center !important;
  padding: 11px !important;
  transition: all 0.4s;
  max-width: 127px;
  overflow: hidden;

  &:focus {
    box-shadow: none !important;
  }
  &:hover {
    background: $bg-hover !important;
  }
}
