@import "../../../assets/styles/common/colors.scss";
.lookup-container {
  .search-container {
    width: 300px;
    position: relative;
    .input__search {
      background: #f0f1f3;
      border-radius: 3px;
      height: 40px;
      width: 100%;
      padding: 0 12px;
      border: none;

      &:focus {
        outline: 2px solid $primary-color;
      }
    }

    i {
      position: absolute;
      right: 14px;
      top: 20%;
    }
  }
  .lookup-card {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #d7dce0;
  }
  .main__modal {
    max-width: 680px;
  }
}
