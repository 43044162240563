.summary {
  display: flex;
  border: 1px solid #d7dce0;
  padding: 16px;
  height: 120px;

  .kyc {
    flex: 1;
    padding: 0 16px;
    border-right: 1px solid #d7dce0;
    .icon {
      border-radius: 50%;
      color: #175fff;
      width: 32px;
      height: 32px;
      background: rgba(23, 95, 255, 0.1);
    }
  }

  .users {
    flex: 1;
    border-right: 1px solid #d7dce0;
    padding: 0 16px;
    .icon {
      border-radius: 50%;
      color: #175fff;
      width: 32px;
      height: 32px;
      background: rgba(23, 95, 255, 0.1);
    }
  }

  .currency {
    flex: 1;
    padding: 0 16px;
    .icon {
      border-radius: 50%;
      color: #175fff;
      width: 32px;
      height: 32px;
      background: rgba(23, 95, 255, 0.1);
    }
  }
}

.kyc-reports {
  width: 676px;
}

.DateRangePicker {
  &__CalendarSelection {
    background-color: #175fff;
    border: 1px solid #0654fe;
  }
  &__Date {
    &--is-highlighted {
      &:hover {
        // background-color: black;
        border-color: green;
      }
    }
  }
}

@media only screen and (max-width: 680px) {
  .kyc-reports {
    width: 100%;
  }
}
