@import "../../../assets/styles/common/colors.scss";

.panel {
  margin-bottom: 1rem;

  .panel-title {
    font-size: 16px !important;
    color: $spr-500-color;
  }
}
