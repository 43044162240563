.kyc-configuration {
  .no-border-top {
    border-top: none !important;
  }
  .pg-header {
    
    .w-150 {
      width: 150px
    }

  }
  .content-container {
    display: flex;

    .content-left {
      flex: 1;
      height: 100%;

      .top-box {
        width: 100px;
        height: 100px;
        color: #777777;

        background: #f5f5f5;
        border-radius: 5px;
      }

      .left-list {
        list-style: none;
        font-size: 12px;
        padding-left: 0;
      }
    }

    .content-right {
      flex: 4;
      height: 76vh;
      overflow-y: auto;

      .summary {
        display: flex;
        border: 1px solid #d7dce0;
        padding: 16px;
        height: 120px;

        .kyc {
          flex: 1;
          padding: 0 16px;
          border-right: 1px solid #d7dce0;
          .icon {
            border-radius: 50%;
            color: #175fff;
            width: 32px;
            height: 32px;
            background: rgba(23, 95, 255, 0.1);
          }
        }

        .users {
          flex: 1;
          border-right: 1px solid #d7dce0;
          padding: 0 16px;
          .icon {
            border-radius: 50%;
            color: #175fff;
            width: 32px;
            height: 32px;
            background: rgba(23, 95, 255, 0.1);
          }
        }

        .currency {
          flex: 1;
          padding: 0 16px;
          .icon {
            border-radius: 50%;
            color: #175fff;
            width: 32px;
            height: 32px;
            background: rgba(23, 95, 255, 0.1);
          }
        }
      }

      .credited,
      .debited {
        width: 32px;
        height: 32px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .credited {
        color: #175fff;
        background: rgba(23, 95, 255, 0.1);
      }

      .debited {
        color: #e43535;
        background: #fce9e9;
      }
    }

    .search-container {
      width: 300px;
      position: relative;
      i {
        position: absolute;
        top: 25%;
        right: 12px;
        color: #24292e;
      }
    }
  }

  .back-btn {
    cursor: pointer;
  }
}
