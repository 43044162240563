@import "~vgg-ui-react/dist/styles/partials";
@import "../../../assets/styles/common/colors.scss";
.verified-lookup {
  .head-section {
    &__icon {
      background: #e8f0ff;
      border-radius: 6px;
      color: $primary-color;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      padding: 10px;
      cursor: pointer;
      transition: opacity 0.3s;

      i {
        font-size: 25px;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    &__title {
      .pg-title {
        font-weight: $sp-fontweight--600;
      }
      .pg-subtitle {
        font-weight: $sp-fontweight--600;
      }
    }
  }
  .lookup-avatar-container {
    height: max-content;
    width: 300px;
  }
  .lookup-card {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid $gray-100;
  }
  .lookup-avatar {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    margin: 0 auto;
  }
}
