.wallet-instance-container {
  .search-container {
    width: 300px;
    position: relative;
    i {
      position: absolute;
      top: 25%;
      right: 12px;
      color: #24292e;
    }
  }
}
